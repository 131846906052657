// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateUser",
  async (payloadData) => {
    const response = await axios.post("/api/user", payloadData.data)
    return response
  }
)
export const UpdateUser = createAsyncThunk(
  "api/updateUser",
  async (payloadData) => {
    const response = await axios.post("/api/user/UpdateUser", payloadData.data)
    return response
  }
)

export const addData = createAsyncThunk("api/AddUser", async (payloadData) => {
  const response = await axios.post("/api/user", payloadData.data)
  return response
})

// For Listing
export const getData = createAsyncThunk("api/user/view", async (params) => {
  const response = await axios.get("/api/user/view", params)
  return {
    params,
    data: response.data,
    allData: response.data,
    totalPages: 1
  }
})
// For Technician
export const getTechnicianData = createAsyncThunk(
  "api/technician/view",
  async (params) => {
    const response = await axios.get("/api/technician/view", params)
    return {
      params,
      technicianData: response.data
    }
  }
)

// For Edit
export const fetchSingleRecord = createAsyncThunk(
  "api/user/fetch",
  async (params) => {
    const response = await axios.get(`/api/user/${params}`)
    return {
      data: response.data
    }
  }
)
// For delete
export const deleteSingleRecord = createAsyncThunk(
  "api/user/delete",
  async (params) => {
    const response = await axios.delete(`/api/user/${params}`)
    return {
      data: response.data
    }
  }
)

export const appTechnicianSlice = createSlice({
  name: "appTechnician",
  initialState: {
    allData: [],
    dtoData: {},
    newUserData: {},
    technicianData: [],
    guestData: [],
    updated: 0
  },
  reducers: {},
  extraReducers: {
    [getData.fulfilled]: (state, { payload }) => {
      state.dtoData = {}
      state.allData = payload.allData
      state.updated = 0
    },
    [getTechnicianData.fulfilled]: (state, { payload }) => {
      state.technicianData = payload.technicianData
      state.updated = 0
    },
    [fetchSingleRecord.fulfilled]: (state, { payload }) => {
      state.dtoData = payload.data
      state.updated = 0
    },
    [addData.fulfilled]: (state) => {
      state.updated = 1
    },
    [updateData.fulfilled]: (state) => {
      state.updated = 1
    },
    [deleteSingleRecord.fulfilled]: (state) => {
      state.updated = 1
    }
  }
})

export default appTechnicianSlice.reducer
